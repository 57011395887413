// utils
import { keys } from "@project/common"
import { getTotalTimeInSec } from "../utils"

// services
import { DailyAttendanceData } from "../services"

// types
import {
  AttendanceDataProps,
  BreakStatus,
  BreaksRecord,
} from "../components/ConsumerAuthorizedLayout/types"

/**
 *
 * @param breaksRecord 
 * A user can take break upto 5 times in a day, whenever they want
 * So, status of a breakTime can be anything for any given break 
 * ```
 * type AllRecords =
  | "break_time_1"
  | "break_time_2"
  | "break_time_3"
  | "break_time_4"
  | "break_time_5"

 *  type BreakStatus =
  | "not-started"
  | "tracking"
  | "ended"
  | "full-occupancy"
 * ```
 * @returns
 * This function returns proper status
 * 
 */
const getBreakStatus = (breaksRecord: BreaksRecord) => {
  const breakKeys = keys(breaksRecord)

  return breakKeys.reduce<BreakStatus>((accObj, key, index) => {
    const prevStatus = accObj

    if (index === 0) {
      return breaksRecord[key].status
    }

    if (index > 0 && prevStatus === "ended") {
      return breaksRecord[key].status
    }

    return accObj
  }, "not-started" as BreakStatus)
}

export const useTodayAttendance = (
  todayAttendance: DailyAttendanceData,
): Omit<AttendanceDataProps, "currenDate"> => {
  if (!todayAttendance?.data) {
    return null
  }
  const { attendance_data } = todayAttendance?.data

  const seconds = getTotalTimeInSec(attendance_data.date)

  const breaksRecord: BreaksRecord = {
    break_time_1: {
      start_time: attendance_data.break_start_time,
      end_time: attendance_data.break_end_time,
      status: !attendance_data.break_start_time
        ? "not-started"
        : attendance_data.break_end_time
          ? "ended"
          : "tracking",
      totalTimeInSeconds: seconds(
        attendance_data.break_start_time,
        attendance_data.break_end_time,
      ),
    },
    break_time_2: {
      start_time: attendance_data.break_start_time2,
      end_time: attendance_data.break_end_time2,
      status: !attendance_data.break_start_time2
        ? "not-started"
        : attendance_data.break_end_time2
          ? "ended"
          : "tracking",
      totalTimeInSeconds: seconds(
        attendance_data.break_start_time2,
        attendance_data.break_end_time2,
      ),
    },
    break_time_3: {
      start_time: attendance_data.break_start_time3,
      end_time: attendance_data.break_end_time3,
      status: !attendance_data.break_start_time3
        ? "not-started"
        : attendance_data.break_end_time3
          ? "ended"
          : "tracking",
      totalTimeInSeconds: seconds(
        attendance_data.break_start_time3,
        attendance_data.break_end_time3,
      ),
    },
    break_time_4: {
      start_time: attendance_data.break_start_time4,
      end_time: attendance_data.break_end_time4,
      status: !attendance_data.break_start_time4
        ? "not-started"
        : attendance_data.break_end_time4
          ? "ended"
          : "tracking",
      totalTimeInSeconds: seconds(
        attendance_data.break_start_time4,
        attendance_data.break_end_time4,
      ),
    },

    break_time_5: {
      start_time: attendance_data.break_start_time5,
      end_time: attendance_data.break_end_time5,
      status: !attendance_data.break_start_time5
        ? "not-started"
        : attendance_data.break_end_time5
          ? "full-occupancy"
          : "tracking",
      totalTimeInSeconds: seconds(
        attendance_data.break_start_time5,
        attendance_data.break_end_time5,
      ),
    },
  }

  return {
    attendanceStarted: !!attendance_data.start_time,
    attendanceCompleted: !!attendance_data.end_time,
    checkInTime: attendance_data.start_time,
    checkOutTime: attendance_data.end_time,
    isScheduled: todayAttendance?.data.is_scheduled,
    attendanceType: attendance_data.attendance_type,
    serviceType: attendance_data.service_type,
    breaksRecord: breaksRecord,
    breakStatus: getBreakStatus(breaksRecord),
  }
}
