import { useRouter } from "next/router"
import React, { useContext, useEffect } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { useQuery } from "react-query"

// commons
import {
  InitialLoader,
  dynamicLangString,
  useNotification,
} from "@project/common"
import { ConsumerAuthorizedLayout } from "../components"

// hooks
import { useTodayAttendance } from "../hooks/useTodayAttendance"

// services
import { getTodayAttendance } from "../services"

// contexts
import { AuthContext } from "../contexts/AuthProvider"

// types
import { AdditionalCompProps } from "../types"

const WithPrivateRoute = (
  AuthComponent,
  additionalProps?: AdditionalCompProps,
) => {
  return function PrivateComponent(props) {
    const today = dayjs().format("YYYY-MM-DD")

    const router = useRouter()
    const { pathname } = router

    const { user, loading, authenticated, userInformation } =
      useContext(AuthContext)
    const { showToast } = useNotification()

    // ! ## React query
    // 1. Fetch today's attendance data
    const { data: todayAttendance, isLoading: loadingTodayAttendance } =
      useQuery(["attendance-context"], () => getTodayAttendance(today), {
        refetchOnWindowFocus: false,
        onError: () => {
          showToast({
            type: "error",
            message: dynamicLangString([
              t("Something went wrong while fetching your attendance"),
            ]),
          })
        },
      })

    useEffect(() => {
      if (!loading) {
        if (!authenticated) {
          router.replace("/login")
          return
        }
        if (authenticated) {
          if (pathname === "/") {
            router.replace("/")
          } else {
            router.replace(router?.asPath || "/contact-facility")
          }
        }
      }
    }, [authenticated, loading, user])

    if (loading || loadingTodayAttendance) {
      return (
        <InitialLoader
          logoImg={
            <img src={"assets/logo.svg"} height={60} width={60} alt={"logo"} />
          }
        />
      )
    }
    return (
      <>
        {authenticated ? (
          <ConsumerAuthorizedLayout
            currenDate={today}
            user={userInformation}
            additionalProps={additionalProps}
            {...useTodayAttendance(todayAttendance)}
          >
            <AuthComponent {...props} />
          </ConsumerAuthorizedLayout>
        ) : null}
      </>
    )
  }
}

export default WithPrivateRoute
