import { useTranslation } from "react-i18next"
import { PageHead } from "../components"
import { DailyRecord } from "../containers"
import WithPrivateRoute from "../hoc/withPrivateRoute"

const Home = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageHead>{t("Today's program")}</PageHead>
      <DailyRecord />
    </>
  )
}

export default WithPrivateRoute(Home, {
  pageTitle: "Daily record",
})
